import { useState, useContext } from "react";
import clienteAxios from "../config/axios";
import AuthContext from "../context/autenticacion/authContext";

function useRest() {
  const authContext = useContext(AuthContext);
  const { cerrarSesion } = authContext;

  const [loading, setLoading] = useState(false);

  function init() {
    setLoading(false);
  }
  
  const load = async (api) => {
    init();

    const {url, type, datosJson, getParams, funcion }= api;

    //console.log(getParams);
    setLoading(true);
    let result = null;
    
    try {
      

      if (type === "post") {
        result = await clienteAxios.post(url, datosJson);
      }

      if (type === "get") {
        result = await clienteAxios.get(url, getParams ? {
          params : getParams,
        } : null);
      }

      if (type === "delete") {
        result = await clienteAxios.delete(url, getParams ? {
          params : getParams,
        } : null);
      }

      if (type === "put") {
        result = await clienteAxios.put(url, datosJson);
      }

      if (funcion !== null) {
        let id = 0;
        if(getParams ){
           if(getParams.id) {
            id = getParams.id;
           }
        }

        funcion(false, result, id);
      }
    } catch (e) {
      /*  console.log = function(){};
      console.exception= function(){};
      console.error= function(){}; */
      //console.clear();
      //console.log(e);
      if (e.response.data.messages.msg === "0") {
        cerrarSesion();
      } else {
        if (funcion !== null) {
          funcion(true, e.response.data.messages.msg);
        }
      }
    }
    setLoading(false);
  };

  return [loading, load];
}

export default useRest;
