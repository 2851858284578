import React, {useState } from "react";

const useAlerta = () => {
  const [objMensaje, setAlerta] = useState(null);
  
  const MostrarAlerta = () => {
  
    return (
      <>
        {objMensaje ? (
          <div className={`alert alert-${objMensaje.class}`} role="alert">
            <strong className="font-bold">Atencion! </strong>
            <span>{objMensaje.msg}</span>
          </div>
        ) : null}
      </>
    );
  };

  return [setAlerta, MostrarAlerta];
};

export default useAlerta;
