import React from 'react';
import './Spinner.css';

// https://tobiasahlin.com/spinkit/

const Spinner = () => {
    return (
<>
    <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
      <br></br><br></br>
</>
    );
}

export default Spinner;